import { required, minLength, maxLength } from "vuelidate/lib/validators";

function getValidations() {
    return {
        form: {
            nameSurename: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(50),
            },
            twitter: {
                minLength: minLength(0),
                maxLength: maxLength(150),
            },
            instagram: {
                minLength: minLength(0),
                maxLength: maxLength(150),
            },
            web: {
                minLength: minLength(0),
                maxLength: maxLength(150),
            },
            email: {
                //required,
                minLength: minLength(1),
                maxLength: maxLength(50),
            },
            url: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(50),
            },
            seoTitle: {
                minLength: minLength(2),
                maxLength: maxLength(110),
            },
            seoDescription: {
                minLength: minLength(2),
                maxLength: maxLength(250),
            },
            htmlBiography: {
            },
            seoKeywords: {
            },
        },
    }
}

export default { getValidations };