<template>
  <div class="animated fadeIn">
    <PhotoEditorModal
      :modalTitle="$t('edit')"
      :show="editModal"
      @hide="editModal = false"
      :fileModel="editModel"
      :validHeight="editHeight"
      :validWidth="editWidth"
      :cropIds="[]"
      :activeMenu="[
        'flip',
        'crop',
        'resize',
        'draw',
        'shape',
        'icon',
        'text',
        'mask',
        'filter',
      ]"
      @onSave="(base64) => saveAndUpload(base64)"
    >
    </PhotoEditorModal>
    <div
      class="row d-flex justify-content-center"
      style="margin-left: auto !important; margin-right: auto !important"
    >
      <div class="col-xs-12 col-md-8">
        <div class="card user-card-full">
          <div class="row m-l-0 m-r-0">
            <b-col
              sm="3"
              md="4"
              lg="3"
              xl="2"
              class="bg-c-lite-green user-profile"
            >
              <div class="card-block text-center text-white">
                <label>{{ $t("profile") }}</label>
                <div class="m-b-25">
                  <b-img
                    :src="getProfileSource()"
                    class="img-radius"
                    alt="User-Profile-Image"
                    thumbnail
                    rounded
                    @click="editProfile()"
                  />
                  <b-progress
                    :max="100"
                    height="1rem"
                    animated
                    style="margin: 2px"
                    v-if="form.profile.uploadPercentage > 0"
                  >
                    <b-progress-bar
                      :value="form.profile.uploadPercentage"
                      variant="primary"
                    >
                      <span
                        >{{ $t("loading") }}:
                        <strong
                          >{{
                            form.profile.uploadPercentage.toFixed(0)
                          }}%</strong
                        ></span
                      >
                    </b-progress-bar>
                  </b-progress>
                </div>
                <h6 class="f-w-600">
                  <input
                    type="file"
                    accept="image/*"
                    class="d-none"
                    ref="uploadFile"
                    v-on:change="() => fileLoaded()"
                  />
                  <BrandButton
                    :text="$t('browse')"
                    buttonIcon="fa fa-folder-open"
                    buttonVariant="primary"
                    buttonClass="mr-1 btn-brand nowrap"
                    @click="browse()"
                  ></BrandButton>
                </h6>
              </div>

              <div class="card-block text-center text-white">
                <label>{{ $t("featuredImage") }}</label>
                <div class="m-b-25">
                  <b-img
                    :src="getFeaturedImageSource()"
                    class="img-radius"
                    alt="User-Profile-Image"
                    thumbnail
                    rounded
                    @click="editFeaturedImage()"
                  />
                  <b-progress
                    :max="100"
                    height="1rem"
                    animated
                    style="margin: 2px"
                    v-if="form.featuredImage.uploadPercentage > 0"
                  >
                    <b-progress-bar
                      :value="form.featuredImage.uploadPercentage"
                      variant="primary"
                    >
                      <span
                        >{{ $t("loading") }}:
                        <strong
                          >{{
                            form.featuredImage.uploadPercentage.toFixed(0)
                          }}%</strong
                        ></span
                      >
                    </b-progress-bar>
                  </b-progress>
                </div>
                <h6 class="f-w-600">
                  <input
                    type="file"
                    accept="image/*"
                    class="d-none"
                    ref="featuredImageUploadFile"
                    v-on:change="() => featuredImageFileLoaded()"
                  />
                  <BrandButton
                    :text="$t('browse')"
                    buttonIcon="fa fa-folder-open"
                    buttonVariant="primary"
                    buttonClass="mr-1 btn-brand nowrap"
                    @click="browseFeaturedImage()"
                  ></BrandButton>
                </h6>
              </div>
            </b-col>
            <b-col sm="9" md="8" lg="9" xl="10">
              <div class="card-block">
                <h6 class="m-b-20 p-b-5 b-b-default f-w-600">
                  {{ $t("authorTitle") }}
                </h6>
                <div class="row">
                  <div class="col-sm-6">
                    <h6 class="f-w-400">
                      <ValidationableTextBox
                        prename="author"
                        id="nameSurename"
                        icon="fa fa-user"
                        :isRequired="true"
                        v-model="$v.form.nameSurename.$model"
                        :form="$v.form.nameSurename"
                        :value="$v.form.nameSurename"
                        @onLostFocus="onNameLostFocus()"
                      />
                    </h6>
                  </div>
                  <div class="col-sm-6">
                    <h6 class="f-w-400">
                      <ValidationableTextBox
                        prename="author"
                        id="twitter"
                        icon="fa fa-twitter"
                        :isRequired="false"
                        v-model="$v.form.twitter.$model"
                        :form="$v.form.twitter"
                        :value="$v.form.twitter"
                      />
                    </h6>
                  </div>
                  <div class="col-sm-6">
                    <h6 class="f-w-400">
                      <ValidationableTextBox
                        prename="author"
                        id="instagram"
                        icon="fa fa-instagram"
                        :isRequired="false"
                        v-model="$v.form.instagram.$model"
                        :form="$v.form.instagram"
                        :value="$v.form.instagram"
                      />
                    </h6>
                  </div>
                  <div class="col-sm-6">
                    <h6 class="f-w-400">
                      <ValidationableTextBox
                        prename="author"
                        id="web"
                        icon="fa fa-web"
                        :isRequired="false"
                        v-model="$v.form.web.$model"
                        :form="$v.form.web"
                        :value="$v.form.web"
                      />
                    </h6>
                  </div>
                  <div class="col-sm-6">
                    <h6 class="f-w-400">
                      <ValidationableTextBox
                        prename="author"
                        id="email"
                        icon="fa fa-envelope"
                        :isRequired="false"
                        v-model="$v.form.email.$model"
                        :form="$v.form.email"
                        :value="$v.form.email"
                      />
                    </h6>
                  </div>
                  <div class="col-sm-6">
                    <h6 class="f-w-400">
                      <ValidationableTextBox
                        prename="author"
                        id="url"
                        icon="fa fa-link"
                        :isRequired="true"
                        v-model="$v.form.url.$model"
                        :form="$v.form.url"
                        :value="$v.form.url"
                        @keydown="(e) => onUrlKeyDown(e)"
                      />
                    </h6>
                  </div>
                  <div class="col-sm-6">
                    <h6 class="f-w-400">
                      <ValidationableTextBox
                        prename="author"
                        id="seoTitle"
                        icon="fa fa-font"
                        :isRequired="false"
                        v-model="$v.form.seoTitle.$model"
                        :form="$v.form.seoTitle"
                        :value="$v.form.seoTitle"
                      />
                    </h6>
                  </div>
                  <div class="col-sm-6">
                    <h6 class="f-w-400">
                      <ValidationableTextArea
                        prename="author"
                        id="seoDescription"
                        icon="fa fa-font"
                        :isRequired="false"
                        :rows="1"
                        v-model="$v.form.seoDescription.$model"
                        :form="$v.form.seoDescription"
                        :value="$v.form.seoDescription"
                      />
                    </h6>
                  </div>
                  <div class="col-sm-6">
                    <label>{{ $t("seoKeywords") }}</label>
                    <h6 class="f-w-400">
                      <CustomTagInputValidation
                        v-model="keyWords"
                        :keyWords="keyWords"
                        :form="$v.form.seoKeywords"
                        id="seoKeywords"
                        icon="fa fa-tags"
                        prename="author"
                        @changed="(val) => seoKeywordsChanged(val)"
                      />
                    </h6>
                  </div>
                </div>
                <h6 class="m-b-20 m-t-40 p-b-5 b-b-default f-w-600">
                  {{ $t("biography") }}
                </h6>
                <div class="row">
                  <div class="col-sm-12">
                    <TextEditor
                      :content="form.htmlBiography"
                      @input="(val) => (form.htmlBiography = val)"
                    />
                  </div>
                </div>
                <b-row>
                  <b-col sm="12" class="text-right">
                    <BrandButton
                      buttonVariant="success"
                      buttonIcon="fa fa-save"
                      @click="save"
                      class="mt-2"
                      :text="$t('save')"
                    />
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "../../shared/http-base";
import httpGeneral from "../../shared/http";
import authorValidator from "../../validations/author-validator";
import ValidationableTextBox from "../../widgets/form/ValidationableTextBox";
import ValidationableTextArea from "../../widgets/form/ValidationableTextArea";
import CustomTagInputValidation from "../../widgets/form/CustomTagInputValidation";
import BrandButton from "../../widgets/form/CustomBrandButton";
import TextEditor from "../../widgets/form/TextEditor";
import PhotoEditorModal from "../../widgets/news/PhotoEditorModal";
import stringHelper from "../../shared/string-helper";
import generalHelper from "../../shared/utils/general-helper";
import generatorsHelper from "../../shared/utils/generators-helper";
import toastHelper from "../../shared/toast-helper";
import { validationMixin } from "vuelidate";

export default {
  name: "AuthorAdd",
  components: {
    ValidationableTextBox,
    CustomTagInputValidation,
    TextEditor,
    BrandButton,
    PhotoEditorModal,
    ValidationableTextArea,
  },
  mixins: [validationMixin],
  validations: authorValidator.getValidations(),
  methods: {
    editProfile() {
      if (this.form.profile.onlineFile != null) {
        this.editModel = this.form.profile;
        this.isFeaturedImage = false;
        this.editModal = true;
      } else {
        toastHelper.error(this.$t("notUploadedYet"));
      }
    },
    editFeaturedImage() {
      if (this.form.featuredImage.onlineFile != null) {
        this.editModel = this.form.featuredImage;
        this.isFeaturedImage = true;
        this.editModal = true;
      } else {
        toastHelper.error(this.$t("notUploadedYet"));
      }
    },
    getProfileSource() {
      if (
        this.form.profile.localFile == null &&
        this.form.profile.onlineFile == null
      ) {
        return "/img/user.png";
      } else if (this.form.profile.localFile != null) {
        return this.form.profile.localFile;
      } else {
        return this.form.profile.onlineFile.fileName;
      }
    },
    getFeaturedImageSource() {
      if (
        this.form.featuredImage.localFile == null &&
        this.form.featuredImage.onlineFile == null
      ) {
        return "/img/user.png";
      } else if (this.form.featuredImage.localFile != null) {
        return this.form.featuredImage.localFile;
      } else {
        return this.form.featuredImage.onlineFile.fileName;
      }
    },
    saveAndUpload(base64) {
      this.editModal = false;

      if (this.isFeaturedImage) this.removeFeaturedImage(false);
      else this.remove(false);
      var block = base64.split(";");
      var contentType = block[0].split(":")[1];
      var realData = block[1].split(",")[1];
      var blob = generatorsHelper.b64toBlob(realData, contentType);
      if (this.isFeaturedImage) this.form.featuredImage.localFile = base64;
      else this.form.profile.localFile = base64;

      this.startUpload(null, blob);
    },
    browse() {
      this.$refs.uploadFile.click();
    },
    browseFeaturedImage() {
      this.$refs.featuredImageUploadFile.click();
    },
    fileLoaded() {
      let file = this.$refs.uploadFile.files[0];
      if (generalHelper.isImageFile(file)) {
        this.form.profile.uploadPercentage = 1;
        this.form.profile.localFile = URL.createObjectURL(file);
        if (this.form.profile.onlineFile != null) {
          this.remove(false);
        }
        this.calculateImageSize();
        this.startUpload(file);
      } else {
        toastHelper.error(this.$t("fileIsNotValid"));
      }
    },
    featuredImageFileLoaded() {
      let file = this.$refs.featuredImageUploadFile.files[0];
      if (generalHelper.isImageFile(file)) {
        this.form.featuredImage.uploadPercentage = 1;
        this.form.featuredImage.localFile = URL.createObjectURL(file);
        this.isFeaturedImage = true;
        if (this.form.featuredImage.onlineFile != null) {
          this.removeFeaturedImage(false);
        }
        this.calculateImageSize();
        this.startUpload(file);
      } else {
        toastHelper.error(this.$t("fileIsNotValid"));
      }
    },
    startUpload(file, blob = null) {
      let formData = new FormData();
      if (blob != null) {
        formData.append("file", blob, "image.jpg");
      } else {
        formData.append("file", file);
      }
      let self = this;
      http
        .post("/Files/upload", formData, {
          onUploadProgress: function (progressEvent) {
            if (this.isFeaturedImage)
              this.form.featuredImage.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            else
              this.form.profile.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
          }.bind(this),
        })
        .then((response) => {
          if (this.isFeaturedImage) {
            this.form.featuredImage.onlineFile = response.data.data;
            this.form.featuredImage.uploadPercentage = 0;
          } else {
            this.form.profile.onlineFile = response.data.data;
            this.form.profile.uploadPercentage = 0;
          }
          this.isFeaturedImage = false;
        })
        .catch((e) => {
          if (blob == null) {
            if (this.isFeaturedImage) self.remove();
            else self.removeFeaturedImage();
          }
          if (!e.response.data.success) {
            toastHelper.error(e.response.data.message);
          }
        });
    },
    remove(withMessage = true) {
      if (this.form.profile.onlineFile != null) {
        let model = {
          fileId: this.form.profile.onlineFile.id,
        };
        httpGeneral
          .post(
            `Files/deletefilebyid?fileId=${this.form.profile.onlineFile.id}`
          )
          .then((response) => {
            if (withMessage) toastHelper.success(response.message);
          });
      }
      // this.form.profile.localFile = null;
      // this.form.profile.onlineFile = null;
      // this.form.profile.uploadPercentage = 0;
      // this.$refs.uploadFile.value = null;
    },
    removeFeaturedImage(withMessage = true) {
      if (this.form.featuredImage.onlineFile != null) {
        let model = {
          fileId: this.form.featuredImage.onlineFile.id,
        };
        httpGeneral
          .post(
            `Files/deletefilebyid?fileId=${this.form.featuredImage.onlineFile.id}`
          )
          .then((response) => {
            if (withMessage) toastHelper.success(response.message);
          });
      }
      // this.form.featuredImage.localFile = null;
      // this.form.featuredImage.onlineFile = null;
      // this.form.featuredImage.uploadPercentage = 0;
      // this.$refs.featuredImageUploadFile.value = null;
    },
    onNameLostFocus() {
      if (this.form.url == "") {
        this.form.url = stringHelper.standardUrl(this.form.nameSurename);
      }
    },
    onUrlKeyDown(e) {
      if (e.shiftKey || e.altKey || e.ctrlKey) e.returnValue = false;
      else e.returnValue = stringHelper.isValidNumChar(e.key);
    },
    seoKeywordsChanged(val) {
      this.keyWords = val;
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;
      if (this.form.profile.onlineFile == null) {
        toastHelper.error(this.$t("imageRequiredError"));
        return;
      }
      let model = { ...this.author, ...this.form };
      if (this.form.featuredImage.onlineFile != null)
        model.featuredImageFileId = this.form.featuredImage.onlineFile.id;
      model.photoFileId = this.form.profile.onlineFile.id;
      let path = model.id > 0 ? "authors/update" : "authors/add";
      httpGeneral.post(path, model).then((result) => {
        if (result && result.success) {
          toastHelper.success(result.message);
          this.$router.push({ path: "/author-list" });
        }
      });
    },
    calculateImageSize() {
      if (this.form === undefined) return;
      let img = new Image();
      img.onload = () => {
        this.editHeight = img.height;
        this.editWidth = img.width;
      };
      if (this.isFeaturedImage) {
        img.src =
          this.form.featuredImage.localFile == null
            ? this.form.featuredImage.onlineFile.fileName
            : this.form.featuredImage.localFile;
      } else {
        img.src =
          this.form.profile.localFile == null
            ? this.form.profile.onlineFile.fileName
            : this.form.profile.localFile;
      }
      //Load image size
    },

    back() {
      this.$router.back();
    },
    getUpdateForm(authorId) {
      let path = `authors/getbyid?authorId=${authorId}`;
      httpGeneral.get(path).then((author) => {
        if (author) {
          this.author = author;
          this.form.nameSurename = author.nameSurename;
          this.form.twitter = author.twitter;
          this.form.instagram = author.instagram;
          this.form.web = author.web;
          this.form.url = author.url;
          this.form.email = author.email;
          this.form.htmlBiography = author.htmlBiography;
          this.form.seoDescription = author.seoDescription;
          this.form.seoTitle = author.seoTitle;
          this.form.profile = {
            localFile: null,
            onlineFile: author.photoFile,
            uploadPercentage: 0,
          };
          if (author.featuredImageFile != null) {
            this.form.featuredImage = {
              localFile: null,
              onlineFile: author.featuredImageFile,
              uploadPercentage: 0,
            };
          }
          this.keyWords = author.seoKeywords
            ? author.seoKeywords.split(",")
            : null;
          this.calculateImageSize();
        }
      });
    },
  },
  watch: {
    form: {
      handler(obj) {
        let standardUrl = stringHelper.standardUrl(obj.url);
        obj.url = standardUrl;
      },
      deep: true,
    },
    keyWords() {
      let keys = "";
      if (this.keyWords != null) {
        this.keyWords.forEach((element) => {
          keys = keys + "," + element;
        });
        this.author.seoKeywords = keys.substr(1);
      }
    },
  },
  data() {
    return {
      keyWords: [],
      editModal: false,
      editModel: {},
      editHeight: 0,
      editWidth: 0,
      isFeaturedImage: false,
      form: {
        nameSurename: "",
        twitter: "",
        instagram: "",
        web: "",
        url: "",
        email: "",
        seoDescription: "",
        seoTitle: "",
        htmlBiography: "",
        profile: {
          localFile: null,
          onlineFile: null,
          uploadPercentage: 0,
        },
        featuredImage: {
          localFile: null,
          onlineFile: null,
          uploadPercentage: 0,
        },
      },
      author: {
        id: 0,
        nameSurename: "",
        twitter: "",
        instagram: "",
        web: "",
        url: "",
        seoKeywords: "",
        email: "",
        seoDescription: "",
        seoTitle: "",
        htmlBiography: "",
      },
    };
  },
  created() {
    let authorId = this.$route.params.id ? this.$route.params.id : 0;
    if (authorId > 0) this.getUpdateForm(authorId);
  },
};
</script>
<style scoped>
.padding {
  padding: 3rem !important;
}

.user-card-full {
  overflow: hidden;
}

.card {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
  margin-bottom: 30px;
}
.m-r-0 {
  margin-right: 0px;
}

.m-l-0 {
  margin-left: 0px;
}

.user-card-full .user-profile {
  border-radius: 5px 0 0 5px;
}

.bg-c-lite-green {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f29263),
    to(#ee5a6f)
  ) !important;
  background: linear-gradient(to right, #ee5a6f, #f29263) !important;
}

.user-profile {
  padding: 20px 0;
}

.card-block {
  padding: 1.25rem;
}

.m-b-25 {
  margin-bottom: 25px;
}

.img-radius {
  border-radius: 5px;
  height: 120px;
  width: 120px;
  object-fit: contain;
  background-color: white !important;
  cursor: pointer;
}

h6 {
  font-size: 14px;
}

.card .card-block p {
  line-height: 25px;
}

.card-block {
  padding: 1.25rem;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.m-b-20 {
  margin-bottom: 20px;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.card .card-block p {
  line-height: 25px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.text-muted {
  color: #919aa3 !important;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.f-w-600 {
  font-weight: 600;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-t-40 {
  margin-top: 20px;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-t-40 {
  margin-top: 20px;
}

.user-card-full .social-link li {
  display: inline-block;
}

.user-card-full .social-link li a {
  font-size: 20px;
  margin: 0 10px 0 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
</style>